// Landing.js 
#land-container {
    /* The image used */
    background-image: url("../images/bg-cloudy-mountain.jpg");
  
    min-height: 1000px; 
  
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
  
    /* Needed to position the navbar */
    position: relative;
}

#land-content {
    // border: 1px solid blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 220px;
    width: 80%;
    margin: 0 auto; 

    #land-logo {
        width: 100%;
        margin: auto; 
    }

    h3 {
        border: 2px solid black; 
        width: max-content;
        margin: 180px auto 0 auto;
        padding: 10px;
        text-align: center;
        font-size: 30px;
        letter-spacing: 1.3px;
    }
}