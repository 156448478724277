#nav-container {
    // border: 1px solid red;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: max-content;
    width: 100%;
    position: absolute;
    padding-top: 30px;
}

#navbar {
    // border: 1px solid red;
    font-size: 30px;
    display: none;

    p {
        margin: 0;
        padding: 0;
    }
   
}

#nav-logo-link {
    // border: 1px solid lawngreen;
    width: max-content; 
    float: left;
    
    #nav-logo {
        //border: 1px solid red; 
        padding-left: 40px;
        width: 225px; 
    }  
}